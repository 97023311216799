import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

export default function usePersistQueryParam<RootState, Actions>(
  id: string,
  reducerFn: React.Reducer<RootState, Actions>,
  initialState: RootState,
  stateToQueryParamConverter: (state: RootState) => string,
  initializer?: (initialState: RootState) => RootState,
): [RootState, React.Dispatch<Actions>] {
  const navigate = useNavigate();
  const init = initializer ?? ((initialState) => initialState);
  const [state, setState] = useReducer(reducerFn, initialState, init);
  useEffect(() => {
    const filterQueryArguments = stateToQueryParamConverter(state);
    navigate(
      {
        pathname: window.location.pathname,
        search: filterQueryArguments,
      },
      { replace: true },
    );
  }, [id, state]);

  return [state, setState];
}
